// Custom script to move order of divs on mobile view to create an accordion-ish look iso tabs.
var moveTabContentForMobile = function (list, tabNameSecond, breakPoint, tabNameFirst) {
    var tab = $(".tab");
    var tabContent = $(".tab-content");
    var tabLists = document.getElementsByClassName(list + " tab-list"); //Find the tabLists
    var listAmount = tabLists.length;


    if ($(window).outerWidth() <= breakPoint) { // On mobile..
        for (var i = 0; i < tab.length; i++) { // ..move all content divs directly after corresponding tab element
            $(tabContent[i]).insertAfter(tab[i]);
        }

    } else { // On desktop..
        for (i = 0; i < listAmount; i++) { //Per tabLists..
            var tabNameF = tabNameFirst ? tabNameFirst + (i + 1) : false; //form the first part of the name
            var tabAmount = $(tabLists[i]).find(tab).length; //Find how many tabs to loop trough

            for (var j = 0; j < tabAmount; j++) { //Per tab in this tabList..
                var tabName = tabNameF ? tabNameF + tabNameSecond + (j + 1) : tabNameSecond + (j + 1); //form the second part of the name

                if (j == 0) { // If it's the first contentdiv within this tabList
                    $(".tab-content#" + tabName).insertAfter(tabLists[i]); //Move it right after the corresponsing tabList
                } else { //Or stack the other contentdiv's right after their siblings
                    var prevSibling = tabNameF ? tabNameF + tabNameSecond + (j) : tabNameSecond + (j);
                    $(".tab-content#" + tabName).insertAfter(document.getElementById(prevSibling));
                }
            }
        }
    }
};